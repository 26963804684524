<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Please arrange each of the following steps in the correct order for the procedure for Part B
        of this experiment.
      </p>

      <drag-to-rank-input
        v-model="inputs.inputs"
        :items="choicesShuffled"
        class="mb-0"
        style="max-width: 631px"
        prepend-text="First Step"
        append-text="Last Step"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcM1A03_A3_FlowChartB',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputs: [],
      },
      choices: [
        'Fill two burettes with 0.00200 M iron(III) nitrate and distilled water',
        'Label 3 clean and dry test tubes (1-3)',
        'Prepare a solution in each test tube according to the table in procedure',
        'Mix each solution with a stir rod',
        'Rinse a cuvette multiple times with solution to be studied',
        'Fill the cuvette with the solution to be studied',
        'Wipe the sides of the cuvette with a KimWipe',
        'Measure the absorption of the sample',
        'Repeat previous 4 steps for other two solutions',
        'Discard solutions in appropriate containers',
        'Clean up your area',
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    choicesShuffled() {
      return seededShuffle(this.choices, this.seed);
    },
  },
};
</script>
